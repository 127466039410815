import React from "react";
import { PageHeader } from "../components/PageHeader";
import { graphql } from "gatsby";
import SEO from "../components/SEO";

const NotFoundPage = (props) => {
  const { data, location } = props;
  const { wordpressWpSettings, siteSettings, wordpressPage } = data;
  const { title, wordpressUrl } = wordpressWpSettings;
  const { options = null } = siteSettings;
  const { featureImage: featuredImage } = wordpressPage?.acf;

  return (
    <section className="four-oh-four--component">
      <SEO title={`404 | ${title}`} location={location} />
      <PageHeader
        headerTitle="404"
        location={location}
        headerBackgroundImage={featuredImage}
        breadcrumbTitle="404"
      />
      <div className="four-oh-four--message">
        <div className="message-wrapper">
          <h2>Oops</h2>
          <h2>The page “/404.HTML”</h2>
          <h2>Cannot be found!</h2>
        </div>
      </div>
    </section>
  );
};

export default NotFoundPage;

export const pageQuery = graphql`
  query {
    wordpressWpSettings {
      title
      wordpressUrl
    }
    siteSettings: wordpressAcfOptions {
      options {
        customCss
      }
    }
    wordpressPage: wordpressPage(path: { eq: "/news-and-media/" }) {
      acf {
        title
        featureImage {
          source_url
          id
          localFile {
            childImageSharp {
              fluid(maxWidth: 670, quality: 70) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
      }
    }
  }
`;
